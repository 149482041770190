<template>
	<settingLayout>
		<section class="wrap_s_block stripe">
			<div class="title_section">
                <h2 class="name_section">Stripe</h2>
			</div>
			<div class="body_ stripe_account_info">
				<stripeStatus></stripeStatus>
			</div>
		</section>
		<section class="wrap_s_block earnings">
			<div class="title_section">
                <h2 class="name_section">Earnings</h2>
			</div>

		</section>
	</settingLayout>
</template>

<script>
import settingLayout from "@/layouts/settingLayout";
import stripeStatus from "@/components/stripeStatus.vue";

export default {
	name: "payments",
	components: {
		settingLayout,
		stripeStatus
	},
	data() {
		return {
		}
	},
	computed: {

	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style lang="scss">

</style>